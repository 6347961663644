
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import QuickSearchItem from '@/models/metrics/quickSearchItem';

@Component
export default class QuickSearchButton extends Vue {
  @Prop({ default: 'is-right' })
  private position!: string;

  private timeAxisSpans: FilterTimeAxisSpanEnum[] = [];
  @Prop({ default: FilterTimeAxisSpanEnum.Day })
  private value!: FilterTimeAxisSpanEnum;
  @Prop({ required: true })
  private dataFetch!: () => Promise<Array<{ id: any; name: string }>>;

  private data: QuickSearchItem[] = [];

  private selectedName: string = '';
  private infoLoaded: boolean = false;

  private async mounted() {
    await this.initialize();
  }

  private async initialize() {
    this.infoLoaded = false;
    this.data = await this.dataFetch();
    this.selectedName = '';
    this.infoLoaded = true;
  }

  private get placeholderText() {
    const index = Math.floor(Math.random() * this.data.length);
    return this.$t('report.example_abbrev', [this.data[index].name]);
  }

  @Emit('select')
  private select(selection: QuickSearchItem) {
    // Empty
  }

  private get filteredData() {
    return this.data.filter(
      (entry) => entry.name.toLowerCase().indexOf(this.selectedName.toLowerCase()) >= 0,
    );
  }

  @Watch('dataFetch')
  private async dataFetchMethodUpdated() {
    await this.initialize();
  }
}
